export const usePage = (scrollToTop = false) => {
  const route = useRoute();

  const page = ref<number>(parseInt(route.query.page as string) || 1);

  watch(page, () => {
    if (scrollToTop)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  });

  return page;
};
